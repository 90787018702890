import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function ToolsNAcceleratorsMobile() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--tools section--tools-content section--tools-bg --section-normal-tb-padding">
      <div className="container">
        <div className="container__row --flex-center">
          <div className="container__col-md-6 container__block_display --section-large-tb-padding">
            <h2 className="container__heading heading_margin">
              Tools and Accelerators
            </h2>
            <p className="container__content">
              Our continously efforts to build common repeatable solutions as
              tools ensures providing faster time to market and redueced effor
              on our engagements. These tools are also available to be
              distrubted stand-alone, reach out to us to learn more.
            </p>
            <a
              className="card__action learn_more --action-link"
              href="javascript:void(0);"
              onClick={openModal}
            >
              learn More
            </a>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              src="../../solutions/solutions/tools and acc.png"
              alt="Tools and Accelerators"
            />
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="support-tools-portals-tools and accelerators"
        page="support-tools-portals"
       
      />
    </section>
  );
}

export default ToolsNAcceleratorsMobile;
