import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function MobileSolutions() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner section--gray-bg">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading">Mobile Solutions </h1>
            <h2 className="container__sub-heading">
              Boost reach with fast, easy to use, <br />
              and <span className="--red-color">maintainable mobile apps</span>
            </h2>
            <h6 className="container__content">
              We help deliver an{" "}
              <span className="--red-color">
                engaging and purpose-driven mobile experience
              </span>{" "}
              by combining mobile-first design services, expert development
              support across cutting-edge frameworks/platforms, and
              comprehensive integration capabilities.
            </h6>
            <a onClick={openModal}>
              <button className="container__button">LET’S TALK</button>
            </a>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/solutions/mobile-solutions.png"
              alt="Mobile Solutions"
            />
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="MobileSolutions-Banner"
        page="MobileSolutions"
       
      />
    </section>
  );
}

export default MobileSolutions;

// import React from "react";

// function MobileSolutions() {
//   return (
//     <section className="section section--banner section--gray-bg">
//       <div className="container">
//         <div className="container__row">
//           <div className="container__col-md-6 --section-large-tb-padding">
//             <h1 className="container__heading">Mobile Solutions </h1>
//             <h2 className="container__sub-heading">
//               Boost reach with fast, easy to use, <br />
//               and <span className="--red-color">maintainable mobile apps</span>
//             </h2>
//             <h6 className="container__content">
//               We help deliver an{" "}
//               <span className="--red-color">
//                 engaging and purpose-driven mobile experience
//               </span>{" "}
//               by combining mobile-first design services, expert development
//               support across cutting-edge frameworks/platforms, and
//               comprehensive integration capabilities.
//             </h6>
//             <button className="container__button">LET’S TALK</button>
//           </div>
//           <div className="container__col-md-6 --flex-center">
//             <img
//               className="container__img --bg-absolute-right"
//               src="../../solutions/solutions/mobile-solutions.png"
//               alt="Mobile Solutions"
//             />
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default MobileSolutions;
