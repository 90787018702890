import React from "react";
import TechExpertiseMobile from "../TechExpertiseMobile/TechExpertiseMobile";
import OurProjects from "../OurProjects_MobileSolutions/OurProjects";
import ToolsNAcceleratorsMobile from "../ToolsNAcceleratorsMobile/ToolsNAcceleratorsMobile";

function MultiSectionMobileSolutions() {
	return (
		<div className="section section--bg-dot-curve">
			<TechExpertiseMobile />
			<OurProjects />
			<ToolsNAcceleratorsMobile />
		</div>
	);
}

export default MultiSectionMobileSolutions;
