import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import MobileSolutions from "../components/Solutions/MobileSolutions/MobileSolutions";
import MultiSectionMobileSolutions from "../components/Solutions/MultiSectionMobileSolutions/MultiSectionMobileSolutions";
import OurPerspectives from "../components/Solutions/OurPerspectives/OurPerspectives";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import { Helmet } from "react-helmet";

function solutionsMobile() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout><Helmet>
    <meta charSet="utf-8" />
       <title>
   Hashout Technologies - Mobile Solutions
       </title>
       <meta
         name="description"
         content="Boost reach with fast, easy to use,
         and maintainable mobile apps."
       ></meta></Helmet>

      <SolutionsHelmet />
      <MobileSolutions />
      <MultiSectionMobileSolutions />
      <OurPerspectives />
      <LearnAboutUs page="mobile-solutions"/>
      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}

export default solutionsMobile;
