import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function TechExpertiseMobile() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--technologies --section-normal-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Technologies we are experts at</h2>
          </div>
          <div className="container__col-md-4">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/technologies/react-native.png"
                alt="React Native"
              />
              <h5 className="card__title">React Native</h5>
              <div className="card__description">
                Combine the best parts of native app development with React, and
                create truly native apps that don’t compromise your user
                experiences.
              </div>
              <a
                className="card__action --action-link"
                href="javascript:void(0);"
                onClick={openModal}
              >
                start a project
              </a>
            </div>
          </div>
          <div className="container__col-md-4">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/technologies/flutter.png"
                alt="Flutter"
              />
              <h5 className="card__title">Flutter</h5>
              <div className="card__description">
                Build, test, and deploy beautiful mobile, web, desktop, and
                embedded apps from a single codebase.
              </div>
              <a
                className="card__action --action-link"
                href="javascript:void(0);"
                onClick={openModal}
              >
                start a project
              </a>
            </div>
          </div>
          <div className="container__col-md-4">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/technologies/unity.png"
                alt="Unity"
              />
              <h5 className="card__title">Unity</h5>
              <div className="card__description">
                Gain a competitive edge with an immersive 3D-based product,
                tradeshow, and digital marketing experiences.
              </div>
              <a
                className="card__action --action-link"
                href="javascript:void(0);"
                onClick={openModal}
              >
                start a project
              </a>
            </div>
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="mobile-solutions-Tech-expertise"
        page="mobile-solutions"
       
      />
    </section>
  );
}

export default TechExpertiseMobile;

// import React from "react";

// function TechExpertiseMobile() {
// 	return (
// 		<section className="section section--technologies --section-normal-tb-padding">
// 			<div className="container">
// 				<div className="container__row">
// 					<div className="container__col-xs-12">
// 						<h2 className="container__heading">Technologies we are experts at</h2>
// 					</div>
// 					<div className="container__col-md-4">
// 						<div className="card">
// 							<img
// 								className="card__image"
// 								src="../../solutions/solutions/technologies/react-native.png"
// 								alt="React Native"
// 							/>
// 							<h5 className="card__title">React Native</h5>
// 							<div className="card__description">
// 								Combine the best parts of native app development with React, and
// 								create truly native apps that don’t compromise your user
// 								experiences.
// 							</div>
// 							<a className="card__action --action-link" href="">
// 								start a project
// 							</a>
// 						</div>
// 					</div>
// 					<div className="container__col-md-4">
// 						<div className="card">
// 							<img
// 								className="card__image"
// 								src="../../solutions/solutions/technologies/flutter.png"
// 								alt="Flutter"
// 							/>
// 							<h5 className="card__title">Flutter</h5>
// 							<div className="card__description">
// 								Build, test, and deploy beautiful mobile, web, desktop, and
// 								embedded apps from a single codebase.
// 							</div>
// 							<a className="card__action --action-link" href="javascript:void(0);">
// 								start a project
// 							</a>
// 						</div>
// 					</div>
// 					<div className="container__col-md-4">
// 						<div className="card">
// 							<img
// 								className="card__image"
// 								src="../../solutions/solutions/technologies/unity.png"
// 								alt="Unity"
// 							/>
// 							<h5 className="card__title">Unity</h5>
// 							<div className="card__description">
// 								Gain a competitive edge with an immersive 3D-based product,
// 								tradeshow, and digital marketing experiences.
// 							</div>
// 							<a className="card__action --action-link" href="javascript:void(0);">
// 								start a project
// 							</a>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</section>
// 	);
// }
// export default TechExpertiseMobile;
